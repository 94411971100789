<template>
  <section v-if="content.length > 0" class="section--partners">
    <h2 class="text-44 text-bold title-mb">Партнеры номинации</h2>
    <PartnersList :partners="content" />
  </section>
</template>
<script setup lang="ts">
import { getGlobals } from "@/backend/composables/base"
const globalData = await getGlobals()
const content = computed(() => {
  return globalData?.attributes?.partners
})
</script>
<style lang="scss">
.section {
  &--partners {
    margin: 23px 0 0 0;
    @include md {
      margin: 13px 0 0 0;
    }
    @include sm {
      margin: 10px 0 0 0;
    }
  }
}
</style>
